import React from 'react';
import type { AppWidgetProps } from '../../../types/common';
import Checkout from '../../PackagePicker/Widget/Checkout';

const CheckoutWidget: React.FC<AppWidgetProps> = (props) => {
  return (
    <Checkout
      {...props}
      selectedPlan={{ formId: 'id' }}
      skipAdditionalInfoStep
      siteStyles={props.host.style}
      integrationData={{}}
      viewMode="Preview"
    />
  );
};

export default CheckoutWidget;
