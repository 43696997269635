import React from 'react';
import type { AppWidgetProps } from '../../../types/common';
import CssVars from '../../PackagePicker/CssVars';
import CheckoutWidget from './CheckoutWidget';

export default function (props: AppWidgetProps) {
  return (
    <>
      <CheckoutWidget {...props} />
      <CssVars id={props.host.id} style={props.host.style} />
    </>
  );
}
